/*
 * @Description: 项目配置文件
 * @Author: Mars
 * @Date: 2023-11-20 09:51:50
 * @LastEditTime: 2024-12-27 09:44:25
 */
import { ISystemConfig } from '@/services/system/types';
import { languageList } from './languageList';

const config = {
  /** 翻译文件地址 */
  localePath: import.meta.env.VITE_LOCALE_SRC,
  /** 语言列表 */
  languageList: languageList,
  /** 静态资源地址 */
  staticPath: import.meta.env.VITE_STATIC_RES_PREFIX,
  /** 调取时区的接口key */
  abstractTimeZoneAPIKey: '9236d662ce654d859056bdecae2e0d43',
  /** 文件下载前缀 */
  downloadUrl: import.meta.env.VITE_DOWNLOAD_PREFIX,
  /** GA key */
  gaTrackingId: 'G-B3RYFT09DQ',
  /** 系统id */
  systemId: import.meta.env.VITE_SYSTEM_ID,
  /** 系统配置 */
  systemConfig: {
    carouselNum: 4,
    dataCenter: [
      {
        url: import.meta.env.VITE_DATA_CENTER_CN,
        label: 'register.domestic',
        key: 'CN',
      },
      {
        url: import.meta.env.VITE_DATA_CENTER_SG,
        label: 'register.overseas',
        key: 'SG',
      },
      {
        url: import.meta.env.VITE_DATA_CENTER_AU,
        label: 'register.australiaNode',
        key: 'AU',
      },
      {
        url: import.meta.env.VITE_DATA_CENTER_EU,
        label: 'register.europeanNode',
        key: 'EU',
      },
    ],
    copyRight: 'All rights reserved © Alpha ESS Co., Ltd.',
    filingNumber: '苏ICP备2021040727号-2',
  } as ISystemConfig,
  /** 百度地图key */
  baiduMapKey: '1cUeMmeZtmBlSBzxwKd5nmodlD8gInVk',
  /** 谷歌地图key */
  googleMapKey: import.meta.env.VITE_GOOGLE_MAP_KEY,
  /** 是否第三方登录 */
  thirdLogin: import.meta.env.VITE_THIRD_LOGIN === 'true',
  /** 多数据中心sdc映射 */
  dataCenterMap: new Map([
    [10, 'CN'],
    [11, 'SG'],
    [20, 'EU'],
    [30, 'AU'],
  ]),
  /** 接收token的url集合 */
  ssoUrl: [
    'http://192.168.128.26:5173/', //本地联调
    'http://dev.alphaess.com:8084/', //dev
    'https://www.carbon2030.com:8086/', // 中试
    'https://huidu-partner.alphaess.com/', // 灰度
    'https://huidu-eurpartner.alphaess.com/', //eur灰度
    'https://partner.alphaess.com/', // 正式
    'https://huiducni.alphaess.com/', // 工商业多数据中心灰度环境
    'http://172.25.13.151:3000/', // 扈振中
    'https://cni.alphaess.com/', // 扈振中
    'http://192.168.8.64:8102/', // 扈振中
  ],
  /** OSC测试地址 */
  oscUrl: import.meta.env.VITE_OSC_URL,
};

/** 是否为开发环境 */
export const isDev = import.meta.env.DEV;

export default config;
